
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import orgService from "@/DataProviders/orgService";

export default defineComponent({
  name: "App",
  computed: {
    ...mapGetters(["isLogged"])
  },

  data() {
    return {
      orgName: "",
      drawer: false
    };
  },

  methods: {},
  mounted() {
    console.log(this.isLogged);
    orgService.getOrg().then((response) => {
      this.orgName = response.data.name;
    });
  }

});
