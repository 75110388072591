import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/components/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/product",
    name: "product",
    component: () => import("@/components/productList.vue")
  },

  {
    path: "/add-product/",
    name: "add-product",
    component: () => import("@/components/admin/add-product.vue")
  },
  {
    path: "/edit-product/:id",
    name: "edit-product",
    component: () => import("@/components/admin/edit-product.vue"),
    props: true

  },

  {
    path: "/add-user",
    name: "add-user",
    component: () => import("@/components/admin/add-user.vue")
  },

  {
    path: "/add-doc",
    name: "add-doc",
    component: () => import("@/components/admin/add-doc.vue")
  },

  {
    path: "/edit-doc/:id",
    name: "edit-doc",
    component: () => import("@/components/admin/edit-doc.vue"),
    props: true
  },

  {
    path: "/about",
    name: "about",
    component: () => import("@/components/org.vue")
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () => import("@/components/feedback.vue")
  },
  {
    path: "/docs",
    name: "docs",
    component: () => import("@/components/docs.vue")
  },
  {
    path: "/trades",
    name: "trades",
    component: () => import("@/components/trade.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/Login.vue")
  },

  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/components/logout.vue')
  },

  {
    path: '/buy/:id',
    name: 'buy',
    component: () => import('@/components/buy.vue'),
    props: true
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/components/Settings.vue")
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
